 <!-- mobile navigation menu to show -->
 <div class="netflix-nav netflix-nav-black" #mobileMenu *ngIf="entete_type != undefined">
   <div class="netflix-nav netflix-nav-red">
     <div class="netflix-nav netflix-nav-white">
       <div class="netflix-nav-container column-center">
         <button class="netflix-nav-btn netflix-close-btn" (click)="closeMenuMobile()">
           <img src="../../../../assets/images/SVG/close-gray.svg" alt="" />
         </button>
         <!-- lien logo -->
         <a class="logo" [routerLink]="['/']">
           <img src="../../../../assets/images/SVG/logo.svg" alt="" />
         </a>
         <!-- container -->
         <div class="column-center wrap w-100 mobile-menu-items" *ngIf="bd_service.public_elements.length > 0 && elements_du_menu_type != undefined">


           <a class="top-menu-item title medium bold gray2 mt-3 hover-to-primary" [appRouterOrHref]="{element: element, field_key: 'menu_item_element_page_link'}" routerLinkActive="active" [appRemoveOnNull]="element.contenu.menu_item_element_title" *ngFor="let element of not_contact_elements_for_header; let i = index;">
             {{element.contenu.menu_item_element_title}}

           </a>

           <div class="d-flex flex-row align-items-center mt-5">
             <!-- Espace Membre -->
             <a [appRouterOrHref]="{element: espace_membre_element, field_key: 'menu_item_element_page_link'}" *ngIf="userSession.getUser() == undefined" [appRemoveOnNull]="espace_membre_element.contenu.menu_item_element_title" class="cta cta-primary mr-4">
               {{espace_membre_element.contenu.menu_item_element_title}}
             </a>
             <div *ngIf="userSession.getUser() != undefined" #user_popover2 id="user_popover2" tabindex="0" data-popover-template="#pop1_templ" class="cta cta-primary mr-4 user-popover">
               <img [src]="bd_service.PROFILE_BASE_URL + '/' + userSession.getUser().photo_path" *ngIf="userSession.getUser().photo_path != null" class="" alt="">
               <img src="../../../../assets/images/SVG/popover-avatar.svg" *ngIf="userSession.getUser().photo_path == null" class="" alt="">
               <span class="primary small title bold">{{userSession.getUser().prenoms}}</span>
               <i class="fa fa-caret-down ml-2 primary"></i>
             </div>
             <!-- Urgence -->
             <a href="tel:410" class="cta cta-red">
               <img class="h-80" src="../../../../assets/images/SVG/phone-ringing.svg" alt="">
               <span class="ml-2">Urgence</span>
             </a>
           </div>

         </div>
         <div class="space"></div>
         <!-- footer -->
         <img src="../../../../assets/images/SVG/logo-mark.svg" alt="" class="mobile-menu-footer-img w-50">
       </div>
     </div>
   </div>
 </div>
 <!-- header -->
 <header [@slideInTop] [@slideOutTop] class="d-flex flex-column align-items-center w-100" *ngIf="entete_type != undefined">

   <!-- top bar
  -->
   <div class="top-bar d-none d-lg-flex flex-row align-items-center section-content p-3">
     <!-- top-bar-info -->
     <div class="d-flex flex-row align-items-center top-bar-info mr-auto" [appRemoveOnNull]="entete_type.contenu.top_bar_left_element_text">
       <div class="d-flex flex-row align-items-center w-15em h-15em primary back-opacity-10 rounded-full mr-2">
         <i [class]="'xsmall mx-auto fa fa-' + entete_type.contenu.top_bar_left_element_icon"></i>
       </div>
       <span [appRemoveOnNull]="entete_type.contenu.top_bar_left_element_text" class="small bold gray2">{{entete_type.contenu.top_bar_left_element_text}}</span>
     </div>
     <!-- top-bar-info -->
     <div class="d-flex flex-row align-items-center top-bar-info" [appRemoveOnNull]="entete_type.contenu.top_bar_right_element_text">
       <div class="d-flex flex-row align-items-center w-15em h-15em primary back-opacity-10 rounded-full mr-2">
         <i [class]="'xsmall mx-auto fa fa-' + entete_type.contenu.top_bar_right_element_icon"></i>
       </div>
       <span [appRemoveOnNull]="entete_type.contenu.top_bar_right_element_text" class="small bold gray2">{{entete_type.contenu.top_bar_right_element_text}}</span>
     </div>
     <!-- separator -->
     <div class="separator primary-back h-2em mx-3"></div>

     <!-- top-bar-info -->
     <div class="d-flex flex-row align-items-center top-bar-info">
       <a [href]="entete_type.contenu.top_bar_socials_1_link" [appRemoveOnNull]="entete_type.contenu.top_bar_socials_1_link" [appRouterOrHref]="{element: entete_type, field_key: 'top_bar_socials_1_link'}" class="d-flex flex-row align-items-center h-1em mr-2">
         <i [class]="'small primary mx-auto fa fa-' + entete_type.contenu.top_bar_socials_1_icon"></i>
       </a>
       <a [href]="entete_type.contenu.top_bar_socials_2_link" [appRemoveOnNull]="entete_type.contenu.top_bar_socials_2_link" [appRouterOrHref]="{element: entete_type, field_key: 'top_bar_socials_2_link'}" class="d-flex flex-row align-items-center h-1em mr-2">
         <i [class]="'small primary mx-auto fa fa-' + entete_type.contenu.top_bar_socials_2_icon"></i>
       </a>
       <a [href]="entete_type.contenu.top_bar_socials_3_link" [appRemoveOnNull]="entete_type.contenu.top_bar_socials_3_link" [appRouterOrHref]="{element: entete_type, field_key: 'top_bar_socials_3_link'}" class="d-flex flex-row align-items-center h-1em mr-2">
         <i [class]="'small primary mx-auto fa fa-' + entete_type.contenu.top_bar_socials_3_icon"></i>
       </a>

       <span [appRemoveOnNull]="entete_type.contenu.top_bar_socials_text" class="small bold gray2">{{entete_type.contenu.top_bar_socials_text}}</span>
     </div>
     <!-- separator -->
     <div class="separator primary-back h-2em mx-3"></div>
     <a routerLinkActive="active" [appRouterOrHref]="{element: contact_element, field_key: 'menu_item_element_page_link'}" [appRemoveOnNull]="contact_element.contenu.menu_item_element_title" *ngIf="contact_element != undefined" class="hover to primary gray2 small bold position-relative">{{contact_element.contenu.menu_item_element_title}}</a>

   </div>
   <!-- menu-header -->
   <div class="menu-header white-back header-shadow d-flex flex-column align-items-center p-3 w-100">
     <!-- section-content -->
     <div class="d-flex flex-row align-items-center section-content" *ngIf="bd_service.public_elements.length > 0">
       <a [routerLink]="['/']" class="mr-auto"><img src="../../../../assets/images/SVG/logo.svg" class="logo" alt=""></a>
       <!-- header-menu -->
       <div class="header-menu flex-row align-items-center d-none d-lg-flex mr-5">
         <!-- Accueil -->
         <a class="top-menu-item gray2 title bold small hover-to-primary ml-4" [appRouterOrHref]="{element: element, field_key: 'menu_item_element_page_link'}" [appRemoveOnNull]="element.contenu.menu_item_element_title" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" *ngFor="let element of not_contact_elements_for_header">
           {{element.contenu.menu_item_element_title}}
         </a>
       </div>
       <!-- Espace Membre -->
       <a [appRouterOrHref]="{element: espace_membre_element, field_key: 'menu_item_element_page_link'}" [@fadeIn] [@fadeOut] *ngIf="espace_membre_element != undefined && userSession.getUser() == undefined" [routerLink]="[bd_service.get_router_link_value(espace_membre_element, 'menu_item_element_page_link')]" class="cta cta-primary mr-4 d-none d-lg-flex">{{espace_membre_element.contenu.menu_item_element_title}}</a>

       <div *ngIf="userSession.getUser() != undefined" (click)="popover1_click()" [@fadeIn] [@fadeOut] #user_popover1 id="user_popover1" tabindex="0" data-popover-template="#pop1_templ" class="cta cta-primary mr-4 user-popover d-none d-lg-flex">
         <img [src]="bd_service.PROFILE_BASE_URL + '/' + userSession.getUser().photo_path" *ngIf="userSession.getUser().photo_path != null" style="width: 3.5em; height: 3.5em;" class="" alt="">
         <img src="../../../../assets/images/SVG/popover-avatar.svg" *ngIf="userSession.getUser().photo_path == null" class="" style="width: 3.5em; height: 3.5em;" alt="">
         <span class="primary small title bold">{{userSession.getUser().prenoms}}</span>
         <i class="fa fa-caret-down ml-2 primary"></i>
       </div>
       <!-- Content for Popover-->
       <div id="pop1_templ" *ngIf="userSession.getUser() != undefined" class="d-none">
         <div class="popover-heading">
           <span class="fw-light small mr-2">Connecté(e) en tant que :</span> <br> {{userSession.getUser().nom + ' ' + userSession.getUser().prenoms}}
         </div>
         <div class="popover-body">
           <div class="d-flex flex-column w-100">

             <div class="d-flex flex-row align-items-center justify-content-around w-100 my-3">
               <!-- notif -->
               <div (click)="go_to_dashboard_notifs()" class="go_to_dashboard_notifs d-flex flex-row align-items-center w-25em h-25em rounded-full border border-1 primary-border cursor-pointer primary-light1-back position-relative">
                 <i class="fa fa-bell primary medium mx-auto"></i>
                 <i class="fa fa-circle position-absolute indice red xsmall"></i>
               </div>
               <!-- profil -->
               <div (click)="go_to_dashboard_profile()" class="go_to_dashboard_profile d-flex flex-row align-items-center w-25em h-25em rounded-full position-relative" [ngClass]="{'primary-light1-back border border-1 primary-border cursor-pointer': !userSession.has_verified_kyc(), 'accent-light1-back  cursor-default': userSession.has_verified_kyc()}">
                 <i class="fa fa-user-alt medium mx-auto" [ngClass]="{'accent-dark opacity-5': userSession.has_verified_kyc(), 'primary': !userSession.has_verified_kyc()}"></i>
                 <!-- <i class="fa fa-circle position-absolute indice red xsmall"></i> -->
                 <i class="fa position-absolute indice medium" [ngClass]="{'accent-dark opacity-5 fa-check-circle ': userSession.has_verified_kyc(), 'primary cursor-pointer fa-exclamation-circle': !userSession.has_verified_kyc()}"></i>
               </div>
               <!-- mail verify -->
               <div (click)="proceed_to_mail_verify()" class="proceed_to_mail_verify d-flex flex-row align-items-center w-25em h-25em rounded-full position-relative" [ngClass]="{'primary-light1-back border border-1 primary-border cursor-pointer': !userSession.has_verified_mail(), 'accent-light1-back  cursor-default': userSession.has_verified_mail()}">
                 <i class="fa fa-envelope medium mx-auto" [ngClass]="{'accent-dark opacity-5': userSession.has_verified_mail(), 'primary': !userSession.has_verified_mail()}"></i>
                 <i class="fa position-absolute indice medium" [ngClass]="{'accent-dark opacity-5 fa-check-circle ': userSession.has_verified_mail(), 'primary cursor-pointer fa-exclamation-circle': !userSession.has_verified_mail()}"></i>
               </div>
               <!-- phone verify -->
               <div (click)="proceed_to_phone_verify()" class="proceed_to_phone_verify d-flex flex-row align-items-center w-25em h-25em rounded-full position-relative" [ngClass]="{'primary-light1-back border border-1 primary-border cursor-pointer': !userSession.has_verified_phone(), 'accent-light1-back cursor-default': userSession.has_verified_phone()}">
                 <i class="fa fa-phone medium mx-auto" [ngClass]="{'accent-dark opacity-5': userSession.has_verified_phone(), 'primary': !userSession.has_verified_phone()}"></i>
                 <i class="fa position-absolute indice medium" [ngClass]="{'accent-dark opacity-5 fa-check-circle ': userSession.has_verified_phone(), ' fa-exclamation-circle primary cursor-pointer': !userSession.has_verified_phone()}"></i>
               </div>
             </div>
             <div (click)="go_to_dashboard()" class="go_to_dashboard gray3 my-2 regular hover-to-primary cursor-pointer"><i class="fa fa-external-link-alt mr-2"></i> Tableau de bord</div>
             <hr class="my-0">
             <div (click)="logout()" class="logout red my-2 hover-to-red cursor-pointer"> <i class="fa fa-power-off mr-2"></i> Déconnexion</div>
           </div>
         </div>
       </div>

       <!-- Urgence -->
       <a href="tel:410" class="cta cta-red">
         <img class="h-80" src="../../../../assets/images/SVG/phone-ringing.svg" alt="">
         <span class="ml-2 d-none d-lg-flex">Urgence</span>
       </a>
       <!-- mobile navigation opnener -->
       <button class="d-lg-none netflix-nav-btn netflix-open-btn" #mobileBtn (click)="openMobileMenu()" *ngIf="entete_type != undefined">
         <img src="../../../../assets/images/SVG/menu-hamburger.svg" alt="" id="hammenu">
       </button>
     </div>
   </div>
 </header>