/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable prefer-arrow/prefer-arrow-functions */
/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable curly */
/* eslint-disable max-len */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable quotes */
/* eslint-disable @typescript-eslint/quotes */
import {
  ResponseObject,
  Users,
  Articles,
  Categories,
  Elements,
  TypeElements,
  Medias,
  Faqs,
  DataPagination,
  TypeFromStock,
  Roles,
  StatsElements,
} from '../interfaces';
import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { UserSession } from './user-session.service';
import { Observable, Subject, Subscription } from 'rxjs';
import * as _ from 'lodash';
import * as $ from 'jquery';
import { Router } from '@angular/router';
import { UtilsService } from './utils.service';

@Injectable()
export class BdService implements OnDestroy {
  app_ready: boolean;
  public API_SERVER = 'https://il-prod-site-api.ithousebenin.com/api';
  public API_SERVER_ = 'https://il-prod-user-api.ithousebenin.com/api';
  public SERVER_ = 'https://il-prod-user-api.ithousebenin.com';
  public APP_SERVER = '';
  public USER_DASHBOARD_SERVER = '';
  public TWO_FA_CODE_LENGTH = 6;
  public TWO_FA_CODE_RESEND_DELAY = 60;
  public TAGS_SEPARATOR = '';
  public BASE_URL = '';
  public ALLOWED_FILE_EXTENSIONS: any = [];
  public STATS_ELEMENTS: StatsElements[] = [];
  public TYPES_ELEMENTS: TypeFromStock[] = [];
  public ROUTE_CODE_MAPPING: any[] = [];
  public public_types_elements: TypeElements[] = [];
  public public_elements: Elements[] = [];
  public public_observable: Subject<any>;
  public TYPES_MAP = {
    ENTETE: "entete",
    ESPACE_MEMBRE: "espace_membre",
    QUI_SOMMES_NOUS_SECTION_DACCUEIL: "qui_sommes_nous_section_daccueil",
    ACCUEIL_SECTION_AVIS_TEMOIGNAGES: "accueil_section_avis_temoignages",
    SECTION_PRODUITS_SERVICES: "section_produits_services",
    BLOG_SECTION_DACCUEIL: "blog_section_daccueil",
    SECTION_STATISTIQUES_COMPTEURS: "section_statistiques_compteurs",
    ACCUEIL_SECTION_BLOG: "accueil_section_blog",
    SECTION_NOS_PARTENAIRES: "section_nos_partenaires",
    CONTACT_FORMULAIRE_DE_CONTACT: "contact_formulaire_de_contact",
    SECTION_QUI_SOMMES_NOUS: "section_qui_sommes_nous",
    BLOG_VIDEO_COLONNE_DROITE: "blog_video_colonne_droite",
    ACCUEIL_SECTION_POURQUOI_NOUS_CHOISIR: "accueil_section_pourquoi_nous_choisir",
    SECTION_NOTRE_EQUIPE: "section_notre_equipe",
    ELEMENTS_DU_MENU: "elements_du_menu",
    FAQ_SECTION_QUESTION_NON_TROUVEE: "faq_section_question_non_trouvee",
    ACCUEIL_CAROUSSEL: "accueil_caroussel",
    SECTION_SERVICES_EN_UNE_PHRASE: "section_services_en_une_phrase",
    CONTACT_COORDONNEES: "contact_coordonnees",
    PIED_DE_PAG: "pied_de_page"
  };
  public public_articles: Articles[] = [];
  public GRANT_TYPE = "password";
  public CLIENT_ID = "99d84dc0-415a-42b7-aa1a-6b719e724cd9";
  public CLIENT_SECRET = "AeITktwl1GBEwduG54fWrUsYZRUjA13bTXGM2MAj";
  public COUNTRIES_ISO: any[] = [];
  PROFILE_BASE_URL: any;
  timestamp: number;

  constructor(
    private httpClient: HttpClient,
    public userSession: UserSession,
    public utils_service: UtilsService,
    private router: Router
  ) {
    //initiate from config.json
    console.log('initiating bd_service');

    this.httpClient.get('../../assets/config.json').subscribe((data) => {
      this.app_ready = true;
      //this.DATA = data['data-obj'];
      this.BASE_URL = data['base-url'];
      this.TAGS_SEPARATOR = data['tags-separator'];
      this.ALLOWED_FILE_EXTENSIONS = data['allowed-file-extensions'].split(", ");
      //this.CLIENT_ID = data['client-id'];
      //this.CLIENT_SECRET = data['client-secret']; //'http://localhost:4200';
      this.API_SERVER = data['api-server'];
      this.APP_SERVER = data['app-server']; //'http://localhost:4200';
      this.COUNTRIES_ISO = data['countries-iso']; //'http://localhost:4200';
      this.TWO_FA_CODE_LENGTH = data['2FA-code-length']; //'http://localhost:4200';
      this.TWO_FA_CODE_RESEND_DELAY = data['2FA-code-resend_delay']; //'http://localhost:4200';
      this.USER_DASHBOARD_SERVER = data['user-dashboard-server']; //'http://localhost:4200';
      this.PROFILE_BASE_URL = data['profile-base-url'];
      this.ROUTE_CODE_MAPPING = data['route-page-mapping'];
      console.log('this.TYPES_ELEMENTS', this.TYPES_ELEMENTS);
      this.get_connected_user();
      //this.get_data();
    });
    this.public_observable = new Subject();
  }

  get_connected_user() {
    const token = localStorage.getItem(this.userSession.TOKEN_STORE_KEY);
    if (token != null) {
      this.getArrayData(
        this.connected(token),
        (data_array: any[], message?: any, success?: boolean) => {
          console.log(data_array);
          this.userSession.setToken(token);
          this.userSession.setUser(data_array[0]);

          console.log('user', this.userSession.user);
        }
      )
    }
  }

  connected(token: string): Observable<ResponseObject> {
    this.userSession.setToken(token);
    let headers = new HttpHeaders();
    if (!_.isNull(this.userSession.getToken()))
      headers = headers.set(
        'Authorization', 'Bearer ' + this.userSession.getToken()
      );

    return this.httpClient.post(this.API_SERVER_ + '/user/auth/connected', {}, {
      headers,
    }) as Observable<ResponseObject>;
  }

  get_router_link_value(element: Elements | TypeElements, field_key: string) {
    if (element?.contenu[field_key + '_link_obj']?.slug == 'custom_link') {
      return undefined;
    }
    return '/' + element?.contenu[field_key + '_link_obj']?.route;
  }
  get_href_link_value(element: Elements | TypeElements, field_key: string) {
    //console.log('field_key value',element?.contenu[field_key]);

    return element?.contenu[field_key];
  }

  get_data() {
    if (new Date().getTime() - this.timestamp < 1000) return;
    this.timestamp = new Date().getTime();
    //console.error("getting data");

    //get elements
    this.getArrayData(
      this.get_elements(),
      (data_array: any[], message: any,) => {
        this.public_elements = data_array;
        this.public_elements.forEach((element, index) => {
          if (typeof element.contenu == 'string') {
            element.contenu = JSON.parse(element.contenu);
            element.contenu.id = JSON.parse(element?.type_element?.contenu)?.slug + '-' + (index + 1)
          }
        });
        this.associate_elements_to_types();
      });
    this.getArrayData(
      this.get_typeElements(),
      (data_array: any[], message: any,) => {
        this.public_types_elements = data_array;
        this.public_types_elements.forEach(type => {
          if (typeof type.contenu == 'string') {
            type.contenu = JSON.parse(type.contenu);
          }
        });
        this.associate_elements_to_types();
      });
    fetch('https://ipinfo.io?token=10164ecbdba7d0')
      .then(response => response.json())
      .then(data => {
        this.getArrayData( 
          this.get_published_articles({ per_page: 50 }, [data.country]),
          (data_array: any[], message: any,) => {
            this.public_articles = data_array;
            console.log('data_array', data_array);
            this.associate_elements_to_types();
            //if (this.public_elements.length != 0 && this.public_types_elements.length != 0) this.public_observable.next("new data ok");
          });
      })
      .catch(error => {
        console.error('Error fetching location data:', error);
      });
  }

  associate_elements_to_types() {
    console.log(this.public_elements.length == 0, this.public_types_elements.length == 0);

    if (this.public_elements.length == 0 || this.public_types_elements.length == 0) return;
    this.public_types_elements.forEach(type => {
      const children = this.userSession.filter_elements_by_type(type.contenu.slug, this.public_elements);
      children.forEach(child => {
        child.contenu.type = { contenu: { slug: type.contenu.slug } };
      });
      type.contenu.children = children;

      this.public_observable.next("new data ok");
    });
  }

  ///find slugify javascript.
  // Source: https://stackoverflow.com/a/1054862 .
  convertToSlug(str: string) {
    str = str.replace(/^\s+|\s+$/g, ''); // trim
    str = str.toLowerCase();

    // remove accents, swap ñ for n, etc
    const from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
    const to = "aaaaeeeeiiiioooouuuunc------";
    for (let i = 0, l = from.length; i < l; i++) {
      str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
      .replace(/\s+/g, '_') // collapse whitespace and replace by -
      .replace(/-+/g, '_') // collapse dashes
      .replace('___', '_')
      .replace('__', '_');

    return str;
  }


  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
  }


  finish_password_reset(email, token, new_password, new_password_confirmation): Observable<ResponseObject> {
    let headers = new HttpHeaders();
    if (!_.isNull(this.userSession.getToken()))
      headers = headers.set(
        'Authorization', 'Bearer ' + this.userSession.getToken()
      );
    var formdata = new FormData();
    formdata.append("process_step", "2");
    formdata.append("token", token);
    formdata.append("new_password", new_password);
    formdata.append("new_password_confirmation", new_password_confirmation);

    return this.httpClient.post(
      this.API_SERVER_ + '/user/auth/password-reset', formdata, { headers: headers }
    ) as Observable<ResponseObject>;
  }
  do_password_reset(email): Observable<ResponseObject> {
    let headers = new HttpHeaders();
    if (!_.isNull(this.userSession.getToken()))
      headers = headers.set(
        'Authorization', 'Bearer ' + this.userSession.getToken()
      );
    var formdata = new FormData();
    formdata.append("process_step", "1");
    formdata.append("email", email);

    return this.httpClient.post(
      this.API_SERVER_ + '/user/auth/password-reset', formdata, { headers: headers }
    ) as Observable<ResponseObject>;
  }
  confirm_password_reset(signature, email, expires, option?): Observable<ResponseObject> {
    let headers = new HttpHeaders();
    if (!_.isNull(this.userSession.getToken()))
      headers = headers.set(
        'Authorization', 'Bearer ' + this.userSession.getToken()
      );
    var formdata = new FormData();
    formdata.append("signature", signature);
    formdata.append("email", email);
    formdata.append("expires", expires);
    formdata.append("option", "2");

    return this.httpClient.post(
      this.API_SERVER_ + '/user/auth/email-verify', formdata, { headers: headers }
    ) as Observable<ResponseObject>;
  }
  verify_mail(signature, email, expires, option?): Observable<ResponseObject> {
    let headers = new HttpHeaders();
    if (!_.isNull(this.userSession.getToken()))
      headers = headers.set(
        'Authorization', 'Bearer ' + this.userSession.getToken()
      );
    var formdata = new FormData();
    formdata.append("signature", signature);
    formdata.append("email", email);
    formdata.append("expires", expires);
    formdata.append("option", "1");

    return this.httpClient.post(
      this.API_SERVER_ + '/user/auth/email-verify', formdata, { headers: headers }
    ) as Observable<ResponseObject>;
  }
  do_inscription(inscription_data: any): Observable<ResponseObject> {
    let headers = new HttpHeaders();
    if (!_.isNull(this.userSession.getToken()))
      headers = headers.set(
        'Authorization', 'Bearer ' + this.userSession.getToken()
      );
    var formdata = new FormData();
    Object.keys(inscription_data).forEach(key => {
      formdata.append(key, inscription_data[key]);
    });

    return this.httpClient.post(
      this.API_SERVER_ + '/user/create', formdata, { headers: headers }
    ) as Observable<ResponseObject>;
  }

  do_connexion(connexion_data: any): Observable<ResponseObject> {
    let headers = new HttpHeaders();
    if (!_.isNull(this.userSession.getToken()))
      headers = headers.set(
        'Authorization', 'Bearer ' + this.userSession.getToken()
      );
    var formdata = new FormData();
    Object.keys(connexion_data).forEach(key => {
      formdata.append(key, connexion_data[key]);
    });

    return this.httpClient.post(
      this.API_SERVER_ + '/oauth/token', formdata, { headers: headers }
    ) as Observable<ResponseObject>;
  }

  /********************
   * Articles requests
   ********************/


  get_pays(
    meta?,
    per_page?: number
  ): Observable<ResponseObject> {
    let headers = new HttpHeaders();
    if (!_.isNull(this.userSession.getToken()))
      headers = headers.set(
        'Authorization', 'Bearer ' + this.userSession.getToken()
      );
    if (per_page == undefined) per_page = 50;
    return this.httpClient.get(
      this.API_SERVER_ + '/pays/index?per_page=' + per_page, { headers: headers }
    ) as Observable<ResponseObject>;
  }
  /********************
   * Articles requests
   ********************/

  create_article(article: Articles): Observable<ResponseObject> {
    //setup headers
    let headers = new HttpHeaders();
    if (!_.isNull(this.userSession.getToken()))
      headers = headers.set(
        'Authorization', 'Bearer ' + this.userSession.getToken()
      );

    //if it is a json, transform all json fields to json string
    article = this.json_safe(article);
    article = Object.assign({}, article);
    delete article.validateur;
    delete article.editeur;

    return this.httpClient.post(
      this.API_SERVER + '/article/create', article, { headers: headers }
    ) as Observable<ResponseObject>;
  }

  update_article(article: Articles): Observable<ResponseObject> {
    //setup headers
    let headers = new HttpHeaders();
    if (!_.isNull(this.userSession.getToken()))
      headers = headers.set(
        'Authorization', 'Bearer ' + this.userSession.getToken()
      );
    //if it is a json, transform all json fields to json string
    article = this.json_safe(article);

    const id = article.id;

    delete article.id;
    delete article.validateur;
    delete article.editeur;
    delete article.updated_at;
    delete article.created_at;

    return this.httpClient.post(
      this.API_SERVER + '/article/create/' + id, article, { headers: headers }
    ) as Observable<ResponseObject>;
  }

  delete_article(id: number): Observable<ResponseObject> {
    //setup headers
    let headers = new HttpHeaders();
    if (!_.isNull(this.userSession.getToken()))
      headers = headers.set(
        'Authorization', 'Bearer ' + this.userSession.getToken()
      );

    return this.httpClient.delete(
      this.API_SERVER + '/article/delete/' + id, { headers: headers }
    ) as Observable<ResponseObject>;
  }

  show_article(id: number): Observable<ResponseObject> {
    //setup headers
    let headers = new HttpHeaders();
    if (!_.isNull(this.userSession.getToken()))
      headers = headers.set(
        'Authorization', 'Bearer ' + this.userSession.getToken()
      );

    return this.httpClient.get(
      this.API_SERVER + '/article/show/' + id, { headers: headers }
    ) as Observable<ResponseObject>;
  }

  get_articles(
    meta?: any,
  ): Observable<ResponseObject> {
    let headers = new HttpHeaders();
    if (!_.isNull(this.userSession.getToken()))
      headers = headers.set(
        'Authorization', 'Bearer ' + this.userSession.getToken()
      );

    return this.httpClient.get(
      this.API_SERVER + '/article/index', { headers: headers }
    ) as Observable<ResponseObject>;
  }
  get_published_articles(
    meta?,
    pays?
  ): Observable<ResponseObject> {
    let headers = new HttpHeaders();
    if (!_.isNull(this.userSession.getToken()))
      headers = headers.set(
        'Authorization', 'Bearer ' + this.userSession.getToken()
      );
    let per_page = meta?.per_page;
    if (per_page == undefined) per_page = 999999999;
    let request = {
      alpha_code2: pays != undefined ? pays : [],
      per_page: per_page

    }
    return this.httpClient.post(
      this.API_SERVER + '/article/liste-zone', request, { headers: headers }
    ) as Observable<ResponseObject>;
  }

  /********************
   * Categories requests
   ********************/

  create_categorie(categorie: Categories): Observable<ResponseObject> {
    //setup headers
    let headers = new HttpHeaders();
    if (!_.isNull(this.userSession.getToken()))
      headers = headers.set(
        'Authorization', 'Bearer ' + this.userSession.getToken()
      );

    //if it is a json, transform all json fields to json string
    categorie = this.json_safe(categorie);

    return this.httpClient.post(
      this.API_SERVER + '/categorie/create', categorie, { headers: headers }
    ) as Observable<ResponseObject>;
  }

  update_categorie(categorie: Categories): Observable<ResponseObject> {
    //setup headers
    let headers = new HttpHeaders();
    if (!_.isNull(this.userSession.getToken()))
      headers = headers.set(
        'Authorization', 'Bearer ' + this.userSession.getToken()
      );
    //if it is a json, transform all json fields to json string
    categorie = this.json_safe(categorie);

    const id = categorie.id;

    delete categorie.id;
    delete categorie.updated_at;
    delete categorie.created_at;

    return this.httpClient.post(
      this.API_SERVER + '/categorie/create/' + id, categorie, { headers: headers }
    ) as Observable<ResponseObject>;
  }

  delete_categorie(id: number): Observable<ResponseObject> {
    //setup headers
    let headers = new HttpHeaders();
    if (!_.isNull(this.userSession.getToken()))
      headers = headers.set(
        'Authorization', 'Bearer ' + this.userSession.getToken()
      );

    return this.httpClient.delete(
      this.API_SERVER + '/categorie/delete/' + id, { headers: headers }
    ) as Observable<ResponseObject>;
  }

  show_categorie(id: number): Observable<ResponseObject> {
    //setup headers
    let headers = new HttpHeaders();
    if (!_.isNull(this.userSession.getToken()))
      headers = headers.set(
        'Authorization', 'Bearer ' + this.userSession.getToken()
      );

    return this.httpClient.get(
      this.API_SERVER + '/categorie/show/' + id, { headers: headers }
    ) as Observable<ResponseObject>;
  }

  get_categories(
    meta?: any,
  ): Observable<ResponseObject> {
    let headers = new HttpHeaders();
    if (!_.isNull(this.userSession.getToken()))
      headers = headers.set(
        'Authorization', 'Bearer ' + this.userSession.getToken()
      );

    return this.httpClient.get(
      this.API_SERVER + '/categorie/index', { headers: headers }
    ) as Observable<ResponseObject>;
  }

  /********************
   * Faqs requests
   ********************/

  create_faq(faq: Faqs): Observable<ResponseObject> {
    //setup headers
    let headers = new HttpHeaders();
    if (!_.isNull(this.userSession.getToken()))
      headers = headers.set(
        'Authorization', 'Bearer ' + this.userSession.getToken()
      );

    //if it is a json, transform all json fields to json string
    faq = this.json_safe(faq);

    return this.httpClient.post(
      this.API_SERVER + '/faq/create', faq, { headers: headers }
    ) as Observable<ResponseObject>;
  }

  update_faq(faq: Faqs): Observable<ResponseObject> {
    //setup headers
    let headers = new HttpHeaders();
    if (!_.isNull(this.userSession.getToken()))
      headers = headers.set(
        'Authorization', 'Bearer ' + this.userSession.getToken()
      );
    //if it is a json, transform all json fields to json string
    faq = this.json_safe(faq);

    const id = faq.id;

    delete faq.id;

    return this.httpClient.post(
      this.API_SERVER + '/faq/create/' + id, faq, { headers: headers }
    ) as Observable<ResponseObject>;
  }

  delete_faq(id: number): Observable<ResponseObject> {
    //setup headers
    let headers = new HttpHeaders();
    if (!_.isNull(this.userSession.getToken()))
      headers = headers.set(
        'Authorization', 'Bearer ' + this.userSession.getToken()
      );

    return this.httpClient.delete(
      this.API_SERVER + '/faq/delete/' + id, { headers: headers }
    ) as Observable<ResponseObject>;
  }

  show_faq(id: number): Observable<ResponseObject> {
    //setup headers
    let headers = new HttpHeaders();
    if (!_.isNull(this.userSession.getToken()))
      headers = headers.set(
        'Authorization', 'Bearer ' + this.userSession.getToken()
      );

    return this.httpClient.get(
      this.API_SERVER + '/faq/show/' + id, { headers: headers }
    ) as Observable<ResponseObject>;
  }

  get_faqs(
    meta?: any,
  ): Observable<ResponseObject> {
    let headers = new HttpHeaders();
    if (!_.isNull(this.userSession.getToken()))
      headers = headers.set(
        'Authorization', 'Bearer ' + this.userSession.getToken()
      );

    return this.httpClient.get(
      this.API_SERVER + '/faq/index', { headers: headers }
    ) as Observable<ResponseObject>;
  }

  /********************
   * Elements requests
   ********************/

  create_element(element: Elements): Observable<ResponseObject> {
    //setup headers
    let headers = new HttpHeaders();
    if (!_.isNull(this.userSession.getToken()))
      headers = headers.set(
        'Authorization', 'Bearer ' + this.userSession.getToken()
      );

    //if it is a json, transform all json fields to json string
    element = this.json_safe(element);

    return this.httpClient.post(
      this.API_SERVER + '/element/create', element, { headers: headers }
    ) as Observable<ResponseObject>;
  }

  update_element(element: Elements): Observable<ResponseObject> {
    //setup headers
    let headers = new HttpHeaders();
    if (!_.isNull(this.userSession.getToken()))
      headers = headers.set(
        'Authorization', 'Bearer ' + this.userSession.getToken()
      );
    //if it is a json, transform all json fields to json string
    element = this.json_safe(element);

    const id = element.id;

    delete element.id;
    delete element.updated_at;
    delete element.created_at;

    return this.httpClient.post(
      this.API_SERVER + '/element/create/' + id, element, { headers: headers }
    ) as Observable<ResponseObject>;
  }

  delete_element(id: number): Observable<ResponseObject> {
    //setup headers
    let headers = new HttpHeaders();
    if (!_.isNull(this.userSession.getToken()))
      headers = headers.set(
        'Authorization', 'Bearer ' + this.userSession.getToken()
      );

    return this.httpClient.delete(
      this.API_SERVER + '/element/delete/' + id, { headers: headers }
    ) as Observable<ResponseObject>;
  }

  show_element(id: number): Observable<ResponseObject> {
    //setup headers
    let headers = new HttpHeaders();
    if (!_.isNull(this.userSession.getToken()))
      headers = headers.set(
        'Authorization', 'Bearer ' + this.userSession.getToken()
      );

    return this.httpClient.get(
      this.API_SERVER + '/element/show/' + id, { headers: headers }
    ) as Observable<ResponseObject>;
  }

  get_elements(
    meta?: undefined,
  ): Observable<ResponseObject> {
    let headers = new HttpHeaders();
    if (!_.isNull(this.userSession.getToken()))
      headers = headers.set(
        'Authorization', 'Bearer ' + this.userSession.getToken()
      );

    return this.httpClient.get(
      this.API_SERVER + '/element/index', { headers: headers }
    ) as Observable<ResponseObject>;
  }


  /********************
   * TypeElements requests
   ********************/

  create_typeElement(typeElement: TypeElements): Observable<ResponseObject> {
    //setup headers
    let headers = new HttpHeaders();
    if (!_.isNull(this.userSession.getToken()))
      headers = headers.set(
        'Authorization', 'Bearer ' + this.userSession.getToken()
      );

    //if it is a json, transform all json fields to json string
    typeElement = this.json_safe(typeElement);

    return this.httpClient.post(
      this.API_SERVER + '/typeElement/create', typeElement, { headers: headers }
    ) as Observable<ResponseObject>;
  }

  update_typeElement(typeElement: TypeElements): Observable<ResponseObject> {
    //setup headers
    let headers = new HttpHeaders();
    if (!_.isNull(this.userSession.getToken()))
      headers = headers.set(
        'Authorization', 'Bearer ' + this.userSession.getToken()
      );
    //if it is a json, transform all json fields to json string
    typeElement = this.json_safe(typeElement);

    const id = typeElement.id;

    delete typeElement.id;
    delete typeElement.updated_at;
    delete typeElement.created_at;

    return this.httpClient.post(
      this.API_SERVER + '/typeElement/create/' + id, typeElement, { headers: headers }
    ) as Observable<ResponseObject>;
  }

  delete_typeElement(id: number): Observable<ResponseObject> {
    //setup headers
    let headers = new HttpHeaders();
    if (!_.isNull(this.userSession.getToken()))
      headers = headers.set(
        'Authorization', 'Bearer ' + this.userSession.getToken()
      );

    return this.httpClient.delete(
      this.API_SERVER + '/typeElement/delete/' + id, { headers: headers }
    ) as Observable<ResponseObject>;
  }

  show_typeElement(id: number): Observable<ResponseObject> {
    //setup headers
    let headers = new HttpHeaders();
    if (!_.isNull(this.userSession.getToken()))
      headers = headers.set(
        'Authorization', 'Bearer ' + this.userSession.getToken()
      );

    return this.httpClient.get(
      this.API_SERVER + '/typeElement/show/' + id, { headers: headers }
    ) as Observable<ResponseObject>;
  }

  get_typeElements(
    meta?: undefined,
  ): Observable<ResponseObject> {
    let headers = new HttpHeaders();
    if (!_.isNull(this.userSession.getToken()))
      headers = headers.set(
        'Authorization', 'Bearer ' + this.userSession.getToken()
      );

    return this.httpClient.get(
      this.API_SERVER + '/typeElement/index', { headers: headers }
    ) as Observable<ResponseObject>;
  }



  /********************
   * Medias requests
   ********************/

  create_media(media: Medias): Observable<ResponseObject> {
    //if it is a json, transform all json fields to json string
    media = this.json_safe(media);

    const formData = new FormData();
    formData.append('path', media.path);
    formData.append('titre', media.titre);
    formData.append('slug', media.slug);
    formData.append('type', media.type);
    formData.append('updated_at', media.updated_at);
    formData.append('created_at', media.created_at);

    const headers = new HttpHeaders({ 'enctype': 'multipart/form-data' });

    return this.httpClient.post(
      this.API_SERVER + '/media/create', formData, { headers: headers }
    ) as Observable<ResponseObject>;
  }

  update_media(media: Medias): Observable<ResponseObject> {
    //setup headers
    let headers = new HttpHeaders();
    if (!_.isNull(this.userSession.getToken()))
      headers = headers.set(
        'Authorization', 'Bearer ' + this.userSession.getToken()
      );
    //if it is a json, transform all json fields to json string
    media = this.json_safe(media);

    const id = media.id;

    delete media.id;
    delete media.updated_at;
    delete media.created_at;

    return this.httpClient.post(
      this.API_SERVER + '/media/create/' + id, media, { headers: headers }
    ) as Observable<ResponseObject>;
  }

  delete_media(id: number): Observable<ResponseObject> {
    //setup headers
    let headers = new HttpHeaders();
    if (!_.isNull(this.userSession.getToken()))
      headers = headers.set(
        'Authorization', 'Bearer ' + this.userSession.getToken()
      );

    return this.httpClient.delete(
      this.API_SERVER + '/media/delete/' + id, { headers: headers }
    ) as Observable<ResponseObject>;
  }

  show_media(id: number): Observable<ResponseObject> {
    //setup headers
    let headers = new HttpHeaders();
    if (!_.isNull(this.userSession.getToken()))
      headers = headers.set(
        'Authorization', 'Bearer ' + this.userSession.getToken()
      );

    return this.httpClient.get(
      this.API_SERVER + '/media/show/' + id, { headers: headers }
    ) as Observable<ResponseObject>;
  }

  get_medias(
    meta?: any,
  ): Observable<ResponseObject> {
    let headers = new HttpHeaders();
    if (!_.isNull(this.userSession.getToken()))
      headers = headers.set(
        'Authorization', 'Bearer ' + this.userSession.getToken()
      );

    return this.httpClient.get(
      this.API_SERVER + '/media/index', { headers: headers }
    ) as Observable<ResponseObject>;
  }


  /********************
       * Users requests
       ********************/

  create_user(user: Users): Observable<ResponseObject> {
    //setup headers
    let headers = new HttpHeaders();
    if (!_.isNull(this.userSession.getToken()))
      headers = headers.set(
        'Authorization', 'Bearer ' + this.userSession.getToken()
      );

    //if it is a json, transform all json fields to json string
    user = this.json_safe(user);

    return this.httpClient.post(
      this.API_SERVER + '/user/create', user, { headers: headers }
    ) as Observable<ResponseObject>;
  }

  update_user(user: Users): Observable<ResponseObject> {
    //setup headers
    let headers = new HttpHeaders();
    if (!_.isNull(this.userSession.getToken()))
      headers = headers.set(
        'Authorization', 'Bearer ' + this.userSession.getToken()
      );
    //if it is a json, transform all json fields to json string
    user = this.json_safe(user);

    const id = user.id;

    delete user.id;
    delete user.updated_at;
    delete user.created_at;

    return this.httpClient.post(
      this.API_SERVER + '/user/create/' + id, user, { headers: headers }
    ) as Observable<ResponseObject>;
  }

  delete_user(id: number): Observable<ResponseObject> {
    //setup headers
    let headers = new HttpHeaders();
    if (!_.isNull(this.userSession.getToken()))
      headers = headers.set(
        'Authorization', 'Bearer ' + this.userSession.getToken()
      );

    return this.httpClient.delete(
      this.API_SERVER + '/user/delete/' + id, { headers: headers }
    ) as Observable<ResponseObject>;
  }

  show_user(id: number): Observable<ResponseObject> {
    //setup headers
    let headers = new HttpHeaders();
    if (!_.isNull(this.userSession.getToken()))
      headers = headers.set(
        'Authorization', 'Bearer ' + this.userSession.getToken()
      );

    return this.httpClient.get(
      this.API_SERVER + '/user/show/' + id, { headers: headers }
    ) as Observable<ResponseObject>;
  }

  get_users(
    meta?: any,
  ): Observable<ResponseObject> {
    let headers = new HttpHeaders();
    if (!_.isNull(this.userSession.getToken()))
      headers = headers.set(
        'Authorization', 'Bearer ' + this.userSession.getToken()
      );

    return this.httpClient.get(
      this.API_SERVER + '/user/index', { headers: headers }
    ) as Observable<ResponseObject>;
  }

  /********************
       * Role requests
       ********************/

  create_role(role: Roles): Observable<ResponseObject> {
    //setup headers
    let headers = new HttpHeaders();
    if (!_.isNull(this.userSession.getToken()))
      headers = headers.set(
        'Authorization', 'Bearer ' + this.userSession.getToken()
      );

    //if it is a json, transform all json fields to json string
    role = this.json_safe(role);

    return this.httpClient.post(
      this.API_SERVER + '/role/create', role, { headers: headers }
    ) as Observable<ResponseObject>;
  }

  update_role(role: Roles): Observable<ResponseObject> {
    //setup headers
    let headers = new HttpHeaders();
    if (!_.isNull(this.userSession.getToken()))
      headers = headers.set(
        'Authorization', 'Bearer ' + this.userSession.getToken()
      );
    //if it is a json, transform all json fields to json string
    role = this.json_safe(role);

    const id = role.id;

    delete role.id;
    delete role.updated_at;
    delete role.created_at;

    return this.httpClient.post(
      this.API_SERVER + '/role/create/' + id, role, { headers: headers }
    ) as Observable<ResponseObject>;
  }

  delete_role(id: number): Observable<ResponseObject> {
    //setup headers
    let headers = new HttpHeaders();
    if (!_.isNull(this.userSession.getToken()))
      headers = headers.set(
        'Authorization', 'Bearer ' + this.userSession.getToken()
      );

    return this.httpClient.delete(
      this.API_SERVER + '/role/delete/' + id, { headers: headers }
    ) as Observable<ResponseObject>;
  }

  show_role(id: number): Observable<ResponseObject> {
    //setup headers
    let headers = new HttpHeaders();
    if (!_.isNull(this.userSession.getToken()))
      headers = headers.set(
        'Authorization', 'Bearer ' + this.userSession.getToken()
      );

    return this.httpClient.get(
      this.API_SERVER + '/role/show/' + id, { headers: headers }
    ) as Observable<ResponseObject>;
  }

  get_roles(
    meta?: any,
  ): Observable<ResponseObject> {
    let headers = new HttpHeaders();
    if (!_.isNull(this.userSession.getToken()))
      headers = headers.set(
        'Authorization', 'Bearer ' + this.userSession.getToken()
      );

    return this.httpClient.get(
      this.API_SERVER + '/user/roles', { headers: headers }
    ) as Observable<ResponseObject>;
  }



  /*********************************************************************************
   * PROTECTED FUNCTIONS (DO NOT TOUCH CODE BELOW) ↓↓↓↓↓↓↓↓*************************
   ************************************************************************************/

  json_safe(variable: TypeElements | Articles | Categories | Faqs | Medias | Users | Roles): any {
    //if it is a json, transform all json fields to json string
    const key_values = this.utils_service.json_as_key_value_pair(variable);

    key_values.forEach((key_value, index) => {
      if (typeof key_value.value == 'object')
        key_values[index].value = JSON.stringify(key_value.value);
    });

    return Object.assign({}, variable);//this.utils_service.key_value_pair_to_json_simple(key_values);
  }

  /**
   * Pour ce connecter à la plateforme
   *
   * @param Pseudo le Nom d'user
   * @param MotDePasse le mot de passe
   * @return le token
   */
  login(email: string, password: string): Observable<ResponseObject> {
    //init payload
    const user = {
      email: email,
      password: password,
    };

    return this.httpClient.post(
      this.API_SERVER + '/user/login', user
    ) as Observable<ResponseObject>;
  }

  /**
   * Pour  déconnecter l'user courant
   *
   * @return status = 1 | 0
   */
  logout(): Observable<ResponseObject> {
    let headers = new HttpHeaders();
    if (!_.isNull(this.userSession.getToken()))
      headers = headers.set(
        'Authorization', 'Bearer ' + this.userSession.getToken()
      );

    return this.httpClient.post(this.API_SERVER + '/user/logout', {}, {
      headers,
    }) as Observable<ResponseObject>;
  }

  dump(): Observable<ResponseObject> {
    let headers = new HttpHeaders();
    if (!_.isNull(this.userSession.getToken()))
      headers = headers.set(
        'Authorization', 'Bearer ' + this.userSession.getToken()
      );
    return this.httpClient.get(this.API_SERVER + '/dump', {
      headers,
    }) as Observable<ResponseObject>;
  }

  public request(methodName: string, params: any): Observable<any> {
    let headers = new HttpHeaders();
    if (!_.isNull(this.userSession.getToken()))
      headers = headers.set(
        'Authorization', 'Bearer ' + this.userSession.getToken()
      );

    return this.httpClient.get(
      '../../assets/fake-api/auth/login-result.json', { headers: headers }
    ) as Observable<ResponseObject>;
  }

  /**
   *  Permet de lancer une requête pour récupérer un tableau de données en gérant la mise en attente sur l'interface user
   *
   * @param request_observable la requête à envoyer au serveur
   * @param function_to_execute code à exécuter après que la requête soit terminée
   * @param dom_element_id_to_update id optionel de l'élément dans le DOM qui doit montrer que le requête est en cours
   */
  getArrayData(
    request_observable: Observable<ResponseObject>,
    function_to_execute: (data_array: any[], message?: any, success?: boolean) => void,
    dom_element_id_to_update?: string,
    bypass_popup?: boolean,
    retry_count?: number,
    function_for_retry?: (timeout: number) => void
  ) {
    const array_data = [];
    // montrer que la requête est en cours
    if (dom_element_id_to_update !== undefined)
      $(dom_element_id_to_update).addClass('waiting');
    // lancer la requete
    const subscription: Subscription = this.requestApi(
      request_observable,
      bypass_popup
    ).subscribe((response_object) => {
      console.log(response_object);
      if (response_object == undefined) {
        response_object = { datas: [], success: true, message: "" };
      }
      if (response_object.datas == null) {
        function_to_execute(response_object.datas);
      } else {
        if (response_object.message == "Erreur de validation des champs") {
          function_to_execute(response_object.datas, response_object.message, response_object.success);
        } else {
          const message: DataPagination = {
            el_per_page_view: response_object.datas.per_page,
            page_view: response_object.datas.current_page - 1,
            total_els: response_object.datas.total
          };

          function_to_execute(response_object.datas.data ? response_object.datas.data : [response_object.datas], message, response_object.success);
        }
      }
      // montrer que la requête est terminé
      $(dom_element_id_to_update).removeClass('waiting');
    });
  }

  /**
   * Permet de lancer la requete vers le serveur en affichant un popup en cas d'erreur et
   * en incluant le cas où le serveur n'aurait pas été joignable faute d'une erreur quelconque
   *
   * @param request_observable la requête à envoyer au serveur
   * @param bypass_popup optionel pour ne pas afficher de popup au cas où il y a erreur (dans le cas du login par exemple)
   * @return undefined si erreur est ou la responseObject reçue en paramètre
   */
  requestApi(
    request_observable: Observable<ResponseObject>,
    bypass_popup?: boolean
  ): Observable<ResponseObject> {
    const response_observable = new Subject<ResponseObject>();

    const subscription: Subscription = request_observable.subscribe(
      (response) => {
        // si tout se passe bien
        this.proceed_requestApi(
          bypass_popup,
          response,
          response_observable
        );
        subscription.unsubscribe();
      },
      (error) => {
        //  s'il y a erreur avant même que la requete atteigne le serveur
        //  constituer une donnée d'erreur
        const response: ResponseObject = {
          message: 'NO_NETWORK',
          datas: undefined,
          success: false
        };
        this.proceed_requestApi(
          bypass_popup,
          response,
          response_observable
        );
        subscription.unsubscribe();
      }
    );
    return response_observable.asObservable();
  }

  private proceed_requestApi(
    bypass_popup: boolean,
    reponse_object: ResponseObject,
    response_observable_to_send: Subject<ResponseObject>
  ) {
    if (bypass_popup) {
      // si l'appelant ne veut pas de popup

      // envoyer la réponse
      response_observable_to_send.next(reponse_object);
    } else {
      // si l'appelant ne trouve aucun inconvénient pour le popup
      //passer à la validation de la réponse qui déclenchera l'affichage du popup d'erreur comme normalement/;
      const verified_response = this.verifyResponseObject(reponse_object);

      // puis envoyer la réponse
      response_observable_to_send.next(verified_response);
    }
    // puis unsubscribe
    response_observable_to_send.unsubscribe();
  }

  /**
   *  Permet de verifier la reponse envoyée par le serveur et d'afficher un popup d'erreur au besoin
   *
   * @param responseObject la réponse du serveur
   * @return undefined si erreur est ou la responseObject reçue en paramètre
   */
  verifyResponseObject(responseObject: ResponseObject): any {
    //console.error(responseObject);

    if (responseObject === undefined || responseObject === null) {

      //alert('Reponse non valide du serveur');

      // Subscribe to the confirmation dialog closed action
    } else if (responseObject.success == false) {

      if (responseObject.message == "Erreur de validation des champs") {
        return responseObject;
      } else {
        //this.modal_service.closeLoader();
        console.error(this.userSession.decodeServerMsg(responseObject.message));
        /*  const confirmation = this._fuseConfirmationService.open({
             title: 'Erreur',
             message:
                 this.userSession.decodeServerMsg(responseObject.message),
             actions: {
                 confirm: {
                     show: true,
                     label: 'C\'est compris',
                     color: 'warn',
                 },
             },
         }); */

        // Subscribe to the confirmation dialog closed action
        //confirmation.afterClosed().subscribe((result) => { });
      }

      $('#ok').trigger('focus');
    } else {
      return responseObject;
    }
  }
}
